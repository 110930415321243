<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <div v-else>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn
            small
            depressed
            color="fmq_gray"
            dark
            outlined
            to="/banco-de-cartas-ic"
          >
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </div>
        <ModelosFormulario
          :artigos="artigosSelecionados"
          :listTags="listTags"
          :listCategorias="listCategorias"
          :listArtigos="listArtigos"
          :listProdutos="listProdutos"
          @send="editar"
          @sendAndView="sendAndView"
          :modelo="modelo"
          :loadingBtn="loadingBtn"
          @buscarArtigo="
            dialogArtigo = true;
            buscarArtigo(formDataArtigo);
          "
          @excluirArtigo="excluirArtigo"
        />
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Modelo editado com sucesso"
      @close="goTo"
    />
    <AlertError :alertError="error" :messageError="messageError" />
    <TableModal
      :dialog="dialogArtigo"
      @close="dialogArtigo = false"
      dialogMessage="Lista de Artigos"
    >
      <ArtigosTabelaBuscar
        :headers="headersArtigos"
        :itens="artigos"
        :total="total"
        :pageText="pageText"
        :pageNumber="page"
        :loading="loadingArtigos"
        @handleFilter="filterArtigo"
        @selecionar="selecionarArtigo"
      >
        <template v-slot:produtos>
          <v-autocomplete
            v-model="formDataArtigo.produtosId"
            :items="listProdutos"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Busque pelo produto"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscarArtigo(formDataArtigo);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:autor>
          <v-text-field
            v-model="formDataArtigo.autor"
            placeholder="Busque pelo autor"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              page = 1;
              buscarArtigo(formDataArtigo);
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:dataPublicacao>
          <v-autocomplete
            v-model="formDataArtigo.dataPublicacao"
            :items="listDatas"
            hide-no-data
            hide-selected
            item-text="value"
            item-value="value"
            placeholder="Busque pelo ano"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscarArtigo(formDataArtigo);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:categorias>
          <v-autocomplete
            v-model="formDataArtigo.categoriasId"
            :items="listCategorias"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Busque pela categoria"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscarArtigo(formDataArtigo);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:titulo>
          <v-text-field
            v-model="formDataArtigo.titulo"
            placeholder="Busque pelo titulo"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              page = 1;
              buscarArtigo(formDataArtigo);
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:tags>
          <v-autocomplete
            v-model="formDataArtigo.tagsId"
            :items="listTags"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Busque pela tag"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              page = 1;
              buscarArtigo(formDataArtigo);
            "
          ></v-autocomplete>
        </template>

        <template v-slot:link>
          <v-text-field
            solo-inverted
            flat
            dense
            :hide-details="true"
            disabled
            readonly
          >
          </v-text-field>
        </template>

        <template v-slot:id>
          <div class="d-flex justify-start">
            <v-btn
              class="mr-2"
              outlined
              x-small
              fab
              color="red"
              @click="clearArtigo"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="searchArtigo">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </ArtigosTabelaBuscar>
    </TableModal>
  </div>
</template>

<script>
import {
  editarModelo,
  exibirModelo,
  visualizarPDFModelo,
} from "@/services/modelos.js";
import { listarArtigos, buscarArtigos } from "@/services/artigos.js";
import { listarProdutos } from "@/services/produtos.js";
import { listarTags } from "@/services/tags.js";
import { listarCategoria } from "@/services/categorias.js";
import ArtigosTabelaBuscar from "@/components/artigos/ArtigosTabelaBuscar.vue";
import ModelosFormulario from "@/components/modelos/ModelosFormulario.vue";
export default {
  name: "BancoDeCartasICEditar",
  components: { ModelosFormulario, ArtigosTabelaBuscar },
  data: () => ({
    breadcrumbs: [
      {
        text: "Banco de cartas",
        disabled: false,
        to: "/banco-de-cartas-ic",
      },
      {
        text: "Editar Modelo de Resposta",
        disabled: true,
        to: "",
      },
    ],
    listCategorias: [],
    listTags: [],
    listProdutos: [],
    listArtigos: [],
    listDatas: [],
    error: false,
    success: false,
    loadingBtn: false,
    messageError: null,
    modelo: {},
    loading: false,
    dialogArtigo: false,
    artigos: [],
    loadingArtigos: false,
    formDataArtigo: {
      orderBy: null,
      orderSorted: null,
      perPage: 10,
      produtosId: null,
      autor: null,
      dataPublicacao: null,
      titulo: null,
      tagsId: null,
      categoriasId: null,
    },
    headersArtigos: [
      { text: "Titulo", value: "titulo" },
      { text: "Autor", value: "autor", sortable: false },
      { text: "Categorias", value: "categorias", sortable: false },
      { text: "Tags", value: "tags", sortable: false },
      { text: "Produtos", value: "produtos", sortable: false },
      { text: "Ano", value: "dataPublicacao", sortable: false },
      { text: "Link", value: "link", sortable: false },
      { text: "Ações", value: "id", sortable: false },
    ],
    artigosSelecionados: [],
    total: null,
    pageText: null,
    page: 1,
  }),
  beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    this.getProducts();
    this.getCategories();
    this.getTags();
    this.getArticles();
    this.listDatas = this.createYearArray();
    this.exibir();
  },
  methods: {
    async editar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await editarModelo(this.$route.params.id, event).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async exibir() {
      this.error = false;
      this.loading = true;
      try {
        const resp = await exibirModelo(this.$route.params.id);
        this.modelo = resp.data;
        this.modelo.artigos.forEach((artigo) => {
          this.selecionarArtigo(artigo);
        });
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async abrirPDF(id) {
      this.error = false;
      this.sucess = false;
      try {
        await visualizarPDFModelo(id).then((resp) => {
          window.open(resp.data.link, "_blank");
        });
      } catch (e) {
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async sendAndView(event) {
      await this.editar(event);
      await this.abrirPDF(this.$route.params.id);
    },
    async getCategories() {
      const resp = await listarCategoria();
      this.listCategorias = resp.data;
    },
    async getTags() {
      const resp = await listarTags();
      this.listTags = resp.data;
    },
    async getProducts() {
      const resp = await listarProdutos();
      this.listProdutos = resp.data;
    },
    async getArticles() {
      const resp = await listarArtigos();
      this.listArtigos = resp.data;
    },
    async buscarArtigo(payload) {
      this.artigos = [];
      try {
        this.loadingArtigos = true;
        const resp = await buscarArtigos(this.page, payload);
        this.artigos = resp.data.data;
        this.total = resp.data.total;
        this.pageText = `${resp.data.from}-${resp.data.to} de ${resp.data.total}`;
      } catch (e) {
        this.loadingArtigos = false;
      } finally {
        this.loadingArtigos = false;
      }
    },
    clearArtigo() {
      this.formDataArtigo.produtosId = null;
      this.formDataArtigo.autor = null;
      this.formDataArtigo.dataPublicacao = null;
      this.formDataArtigo.titulo = null;
      this.formDataArtigo.tagsId = null;
      this.formDataArtigo.categoriasId = null;
      this.page = 1;
      this.buscarArtigo(this.formDataArtigo);
    },
    searchArtigo() {
      this.page = 1;
      this.buscarArtigo(this.formDataArtigo);
    },
    filterArtigo(event) {
      this.formDataArtigo.orderBy = event.orderBy;
      this.formDataArtigo.orderSorted = event.orderSorted;
      this.formDataArtigo.perPage = event.perPage;
      this.page = event.page;
      this.buscarArtigo(this.formDataArtigo);
    },
    selecionarArtigo(event) {
      this.dialogArtigo = false;
      const artigo = this.artigosSelecionados.find(
        (item) => item.id === event.id
      );
      if (!artigo) {
        this.artigosSelecionados.push(event);
      }
    },
    excluirArtigo(event) {
      const indice = this.artigosSelecionados.indexOf(event);
      if (indice !== -1) {
        this.artigosSelecionados.splice(indice, 1);
      }
    },
    goTo() {
      this.sucess = false;
      this.$router.push({ name: "BancoDeCartasIC" });
    },
    createYearArray() {
      const currentYear = new Date().getFullYear();
      const startYear = 1990;
      const yearArray = [];

      for (let year = startYear; year <= currentYear; year++) {
        yearArray.push({ value: year });
      }

      return yearArray;
    },
  },
};
</script>

<style></style>
